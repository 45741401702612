import React from "react";
import { createStyles, Paper, TextField, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import WrappedButton from "./WrappedButton";

interface LoginProps extends WithStyles<typeof styles> {
	setAuthenticated: Function;
}
const styles = (theme: Theme) =>
	createStyles({
		wrapper: {
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundImage: "url('login-background.jpg')",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundColor: theme.palette.background.default,
		},
		loginBox: {
			display: "flex",
			flexDirection: "column",
			padding: "15px 20px",
			textAlign: "center",
			borderRadius: "20px",
		},
		loginInput: {
			marginBottom: "8px",
		},
	});

function Login(props: LoginProps) {
	const [user, setUser] = React.useState("");
	const [pass, setPass] = React.useState("");
	const [url, setUrl] = React.useState("");
	const [isSaving, setSaving] = React.useState(false);
	const [hasError, setHasError] = React.useState(false);

	const checkKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if ((event as any).which === 13) {
			login();
		}
	};

	const login = async () => {
		setSaving(true);
		let dt: any = await fetch("Query/Login", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify({ username: user, password: pass, url: url }),
		});
		if (dt.ok) {
			var res = await dt.json();
			if (res) {
				dt.text().then((y: any) => console.log(y));
				props.setAuthenticated(url);
			} else {
				setHasError(true);
			}
		} else {
			setHasError(true);
		}
		setSaving(false);
	};
	return (
		<div className={props.classes.wrapper}>
			<Paper className={props.classes.loginBox}>
				<TextField
					label="Username"
					variant="outlined"
					value={user}
					className={props.classes.loginInput}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => checkKeyUp(event)}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setUser(event.target.value);
					}}></TextField>
				<TextField
					label="Password"
					type="password"
					variant="outlined"
					value={pass}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => checkKeyUp(event)}
					className={props.classes.loginInput}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setPass(event.target.value);
					}}></TextField>
				<TextField
					label="URL"
					variant="outlined"
					value={url}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => checkKeyUp(event)}
					className={props.classes.loginInput}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setUrl(event.target.value);
					}}></TextField>
				<Typography variant="body1" color={"error"} hidden={!hasError}>
					Invalid username or password.
				</Typography>
				<WrappedButton isBusy={isSaving} caption="Login" onClick={login}></WrappedButton>
			</Paper>
		</div>
	);
}

export default withStyles(styles, { withTheme: true })(Login);
