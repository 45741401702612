import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/snippets/mysql";
import { createStyles, Typography, withStyles, WithStyles, Theme, Button } from "@material-ui/core";
import { isNullOrEmpty } from "../Util";
import ReactAce from "react-ace/lib/ace";
import { Ace } from "ace-builds";
import WrappedButton from "./WrappedButton";

interface QueryProps {
	url: string;
}

export function Querier(props: QueryProps) {
	window.onbeforeunload = function () {
		return window.confirm("Reloading window will require logging in, continue?");
	};

	const [track, setTrack] = React.useState(0);

	React.useEffect(() => {
		execQuery("SELECT licensee + ' - ' + system_name as system_name FROM evolv_cs", true);
	}, [track]);

	const [Query, setQuery] = React.useState("");
	const [queryResults, setQueryResults] = React.useState([] as Array<Array<any>>);
	const [executionTime, setExecTime] = React.useState(0);
	const [isSaving, setSaving] = React.useState(false);
	let aceRef = React.createRef<ReactAce>();

	const getQuery = (editor?: Ace.Editor) => {
		let txt: string = editor?.getSelectedText() ?? aceRef.current?.editor.getSelectedText() ?? "";

		if (isNullOrEmpty(txt)) {
			txt = editor?.getValue() ?? aceRef.current?.editor.getValue() ?? "";
		}
		return txt;
	};

	const runQuery = (editor?: Ace.Editor, args?: any): any => {
		var txt = getQuery(editor);

		execQuery(txt);
	};

	const execQuery = async (query: string, isLoad = false) => {
		if (isNullOrEmpty(query)) return;
		let startTime = new Date().getTime();
		setSaving(true);
		var dt = await fetch("Query/RunQuery", {
			method: "Post",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ query: query, url: props.url }),
		});
		if (dt.ok) {
			dt.json().then((res) => {
				if (!isLoad) {
					setQueryResults(JSON.parse(res));
					setExecTime(new Date().getTime() - startTime);
					setSaving(false);
				} else {
					window.document.title = JSON.parse(res)[0][0].system_name;
					setSaving(false);
				}
			});
		} else {
			dt.text().then((x) => console.log(x));
			setSaving(false);
			alert("An exception occurred");
		}
	};

	const selectAll = () => {
		const elem: HTMLTableElement = document.getElementById("table-area-id") as HTMLTableElement;

		const range = document.createRange();
		const sel = window.getSelection();
		sel?.removeAllRanges();
		try {
			range.selectNodeContents(elem);
			sel?.addRange(range);
		} catch (e) {
			range.selectNode(elem);
			sel?.addRange(range);
		}
	};

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<h3>{"Query Window - " + props.url}</h3>
			<AceEditor
				fontSize={14}
				width={"100%"}
				setOptions={{
					enableLiveAutocompletion: true,
					enableBasicAutocompletion: true,
				}}
				ref={aceRef}
				placeholder="Query"
				mode="mysql"
				theme="twilight"
				showPrintMargin={false}
				showGutter={true}
				height="40%"
				highlightActiveLine={true}
				commands={[{ name: "Run Query", bindKey: { win: "f5", mac: "f5" }, exec: runQuery }]}
				onChange={(val) => {
					setQuery(val);
					console.log(val);
				}}></AceEditor>
			<div style={{ paddingLeft: "8px", display: "flex", alignItems: "baseline", marginBottom: "4px", paddingTop: "4px" }}>
				<WrappedButton isBusy={isSaving} size="small" onClick={() => runQuery()} style={{ marginTop: "8px", marginRight: "8px" }} caption="Run Query" />
				{"Execution Time: " + executionTime + "ms" + " | Results: " + queryResults.reduce((x: number, y: Array<any>) => x + y.length, 0)}
				<Button size="small" style={{ marginLeft: "8px" }} onClick={() => selectAll()} color="primary" variant="contained">
					Select All
				</Button>
			</div>
			<div style={{ backgroundColor: "#ebebeb", overflowY: "auto", flex: "1", padding: "4px" }} id="table-area-id">
				{queryResults.length > 0 && queryResults[0].length > 0 && queryResults[0][0].is_error ? (
					<Typography variant="body1" color="error">
						An error occured: {queryResults[0][0].error_message}
					</Typography>
				) : (
					queryResults.map((x) => <Grid results={x} />)
				)}
			</div>
		</div>
	);
}

const styles = (theme: Theme) =>
	createStyles({
		gridTable: {
			boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			backgroundColor: "white",
			fontSize: "14px",
			marginBottom: "12px",
			"& th": {
				padding: "1px 2px",
				border: "1px solid #ccc",
				borderTop: "none",
				whiteSpace: "nowrap",
			},
			"& td": {
				padding: "1px 2px",
				border: "1px solid #ccc",
				borderTop: "none",
				whiteSpace: "nowrap",
			},
		},
	});

interface ResultGridProps extends WithStyles<typeof styles> {
	results: Array<any>;
}
function ResultGrid(props: ResultGridProps) {
	if (props.results.length == 0) return <></>;

	const keys: Array<string> = Object.keys(props.results[0]);
	return (
		<table className={props.classes.gridTable}>
			<thead>
				<tr>
					{keys.map((x) => (
						<th>{x}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{props.results.map((x) => {
					return (
						<tr>
							{keys.map((y) => (
								<td>{x[y]?.toString()?.trim()}</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

const Grid = withStyles(styles, { withTheme: true })(ResultGrid);
