import React, { Component } from "react";
import { Layout } from "./components/Layout";

import "./custom.css";
import { Querier } from "./components/Querier";
import Login from "./components/Login";

export default class App extends Component {
	static displayName = App.name;
	state = {
		authenticated: false,
		url: null,
	};
	setAuthenticated = (url) => {
		this.setState({ authenticated: true, url: url });
	};

	render() {
		return (
			<Layout>
				{this.state.authenticated && <Querier url={this.state.url} />}
				{!this.state.authenticated && <Login setAuthenticated={this.setAuthenticated} />}
			</Layout>
		);
	}
}
