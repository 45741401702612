import * as React from "react";
import { Button, CircularProgress, createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {
	onClick: Function;
	isBusy?: boolean;
	caption: string;
	className?: string;
	size?: "small" | "large" | "medium" | undefined;
	style?: React.CSSProperties | undefined;
}

function WrappedButton(props: Props) {
	const [isBusy, setIsBusy] = React.useState(false);
	const onClick = async () => {
		setIsBusy(true);
		props.onClick();
		setIsBusy(false);
	};

	let busy = isBusy || props.isBusy;

	const style = Object.assign(props.style ?? {}, { marginLeft: "4px" });

	return (
		<Button size={props.size ?? "large"} disabled={busy} className={props.className ?? ""} onClick={onClick} variant="contained" color="primary">
			{props.caption}
			{busy && <CircularProgress style={style} size={20}></CircularProgress>}
		</Button>
	);
}
export default withStyles(styles, { withTheme: true })(WrappedButton);
